import './register.css';
import { useEffect, useState } from 'react';
import { db, auth, functions } from '../FirebaseAuthConfig.js';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import Loader from './loader.js';

var non_staff_spouse = getCookie('non_staff_spouse') === "true";

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

function Register() {
    
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [secondLoader, setSecondLoader] = useState(false);
    const [currentAuthUser, setCurrentAuthUser] = useState(null);
    

    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [firstEmailValue, setFirstEmailValue] = useState('');
    const [phone, setPhone] = useState('');
    const [firstPhoneValue, setFirstPhoneValue] = useState('');
    // const [shirtSize, setShirtSize] = useState('select');
    const [participationType, setParticipationType] = useState('select');
    const [teamCaptain, setTeamCaptain] = useState('');

    useEffect(() => {
        let unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                setCurrentAuthUser(currentUser);

                const checkUser = httpsCallable(functions, 'checkForUser');
                checkUser()
                .then((response) => {
                var resultData = response.data;

                if (resultData.result === 'userExists - ISregistered') {
                    window.location.replace('/');
                } else {
                    return;
                }
                });
            } else {
                console.log('No logged in user found. Redirecting.');

                window.location.replace('/login');
            }
        });
        return () => {
            unsubscribe()
        }
    }, []);

    useEffect(() => {
        if (!currentAuthUser) {
            return;
        }
        const getUserData = async () => {
            console.log("here");

            const docRef = doc(db, `userCollection/${currentAuthUser.uid}`);
            console.log("beginning wait");
            let getData = await getDoc(docRef);

            console.log("wait complete");
            setUserData(getData.data());
            console.log(getData.data());

            setDisplayName(getData.data().displayName);
            setEmail(getData.data().email);
            setPhone(getData.data().phone);
            setFirstEmailValue(getData.data().email);
            setFirstPhoneValue(getData.data().phone);

            setIsLoading(false);
        }
        
        getUserData();

        return () => {
            // 
        };
    }, [currentAuthUser]);

    const submitData = async (e) => {
        e.preventDefault();

        setSecondLoader(true);

        var userData = {}

        const userRef = doc(db, `userCollection/${currentAuthUser.uid}`);
        await updateDoc(userRef, {
            'displayName': displayName,
            'email': email,
            'phone': phone
        });

        if (participationType === 'teamMember') {
            userData = {
                'userPath': `/userCollection/${currentAuthUser.uid}`,
                'registered': true,
                'team': '',
                'nonStaffSpouse': non_staff_spouse,
                'participationType': participationType,
                'teamCaptain': teamCaptain,
            };
        } else {
            userData = {
                'userPath': `/userCollection/${currentAuthUser.uid}`,
                'registered': true,
                'team': '',
                'nonStaffSpouse': non_staff_spouse,
                'participationType': participationType
            };
        }
        
        const challengeRef = doc(db, `userCollection/${currentAuthUser.uid}/challengeData/immeasurablySore`);
        await setDoc(challengeRef, userData);

        const registeredUserInChallengeColl = doc(db, `challengeCollection/immeasurablySore/registeredUsers/${currentAuthUser.uid}`);
        await setDoc(registeredUserInChallengeColl, userData)
        
        window.location.replace('/');
    }

    if (isLoading) {
        return(
            <div className='Register'>
                <div className='bgIMG' />
                <Loader />
            </div>
        )
    }

    return(
        <div className='Register'>
            <div className='bgIMG' />
            <div className='mainDiv'>
                <form className='infoForm' onSubmit={submitData}>
                    <label className='label firstName'>First Name: <input className='input firstName' type='text' disabled value={userData.firstName} /></label>
                    <label className='label lastName'>Last Name: <input className='input lastName' type='text' disabled value={userData.lastName} /></label>
                    <label className='label displayName'>Display Name: <input className='input displayName' type='text' value={displayName} required onChange={(e) => setDisplayName(e.target.value)} /></label>
                    {firstEmailValue === ""?
                        <label className='label email'>Email Address: <input className='input email' type='text' value={email} onChange={(e) => setEmail(e.target.value)} /></label>
                        :
                        null
                    }
                    {firstEmailValue !== ""?
                        <label className='label email'>Email Address: <input className='input email' type='text' disabled value={email} /></label>
                        :
                        null
                    }
                    {firstPhoneValue === ""?
                        <label className='label phone'>Phone Number: <input className='input phone' type='text' value={phone} onChange={(e) => setPhone(e.target.value)} /></label>
                        :
                        null
                    }
                    {firstPhoneValue !== ""?
                        <label className='label phone'>Phone Number: <input className='input phone' type='text' disabled value={phone} /></label>
                        :
                        null
                    }
                    {/* <label className='label shirtSize' id='label shirtSize'>Shirt Size:
                        <select className='input shirtSize' id='input shirtSize' required onChange={(e) => setShirtSize(e.target.value)}>
                            <option value=''>Select A Shirt Size</option>
                            <option value='XS'>XS</option>
                            <option value='S'>S</option>
                            <option value='M'>M</option>
                            <option value='L'>L</option>
                            <option value='XL'>XL</option>
                            <option value='XXL'>XXL</option>
                            <option value='XXXL'>XXXL</option>
                            <option value='XXXXL'>XXXXL</option>
                        </select>
                    </label> */}
                    <label className='label participationType' id='label participationType'>Category Type:
                        <select className='input participationType' id='input participationType' required onChange={(e) => setParticipationType(e.target.value)}>
                            <option value=''>I want to register as:</option>
                            <option value='teamMember'>Member of my Department/Campus Team</option>
                            <option value='teamCaptain'>Captain of my Department/Campus Team</option>
                            <option value='individual'>Individual (I don't want to be on a team)</option>
                        </select>
                    </label>
                    {participationType === 'teamMember'?
                        <>
                            <label className='label captain'>My team captain is: <i>*Please type N/A if unknown*</i> <input className='input captain' type='text' value={teamCaptain} required onChange={(e) => setTeamCaptain(e.target.value)} /></label>
                            <div className='spacer' />
                        </>
                        :
                        null
                    }
                    <button className='submitButton' type='submit'>Save & Register</button>
                </form>
                <a className='ticketButton' href='https://staff.elevationchurch.org/support/tickets/new' target='_blank'>Is your information incorrect? Click here to submit a ticket.</a>
            </div>
            {secondLoader === true?
                <Loader />
                :
                null
            }
        </div>
    );
}

export default Register;